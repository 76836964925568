<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 gray-bg edit-assigned-resource"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "profile",
              "assigned-resources",
              "assigned-resources"
            )
          }}
        </div>
      </top-header-menu-wrapper>
    </template>

    <section>
      <ul class="clebex-item-section">
        <li class="clebex-item-section-item full-right-underline">
          <div class="clebex-item-content-wrapper">
            <span class="label clebex-item-label">{{
              displayLabelName("profile", "assigned-resources", "release-dates")
            }}</span>
            <span
              class="follow-up-icons cursor-pointer"
              @click="$router.push({ name: 'r_profile-add-break' })"
            >
              <span class="follow-up-icon-item">
                <icon icon="#cx-app1-add" width="12" height="12" />
              </span>
            </span>
          </div>
        </li>
        <template
          v-if="resourceAssignmentBreaks && resourceAssignmentBreaks.length"
        >
          <template v-if="mode === 'selection'">
            <li
              v-for="resourceAssignmentBreak in resourceAssignmentBreaks"
              :key="resourceAssignmentBreak.id"
              class="clebex-item-section-item full-right-underline"
            >
              <div class="checkbox tiny alt block">
                <input
                  type="checkbox"
                  :id="`break${resourceAssignmentBreak.id}`"
                  name="resourceAssignmentBreak"
                  :checked="selectedItems.includes(resourceAssignmentBreak.id)"
                  @change="handleSelection(resourceAssignmentBreak.id)"
                />
                <label :for="`break${resourceAssignmentBreak.id}`">
                  <div class="clebex-item-content-wrapper">
                    <ul class="clebex-item-content-range">
                      <li class="clebex-item-range from">
                        <button class="clebex-item-range-cta">
                          {{
                            displayDate(resourceAssignmentBreak.datetime_from)
                          }}
                        </button>
                      </li>
                      <li class="clebex-item-range separator">
                        <icon
                          icon="#cx-app1-arrow-right-12x12"
                          width="12"
                          height="12"
                        />
                      </li>
                      <li class="clebex-item-range to">
                        <button class="clebex-item-range-cta">
                          {{ displayDate(resourceAssignmentBreak.datetime_to) }}
                        </button>
                      </li>
                    </ul>
                  </div>
                </label>
              </div>
            </li>
          </template>
          <li
            v-else
            v-for="resourceAssignmentBreak in resourceAssignmentBreaks"
            :key="resourceAssignmentBreak.id"
            @click="
              $router.push({
                name: 'r_profile-edit-break',
                params: {
                  breakId: resourceAssignmentBreak.id
                }
              })
            "
            class="clebex-item-section-item full-right-underline"
          >
            <div class="clebex-item-content-wrapper">
              <ul class="clebex-item-content-range">
                <li class="clebex-item-range from">
                  <button class="clebex-item-range-cta">
                    {{ displayDate(resourceAssignmentBreak.datetime_from) }}
                  </button>
                </li>
                <li class="clebex-item-range separator">
                  <icon
                    icon="#cx-app1-arrow-right-12x12"
                    width="12"
                    height="12"
                  />
                </li>
                <li class="clebex-item-range to">
                  <button class="clebex-item-range-cta">
                    {{ displayDate(resourceAssignmentBreak.datetime_to) }}
                  </button>
                </li>
              </ul>
            </div>
          </li>
        </template>
      </ul>
    </section>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              @click="setMode(mode === 'selection' ? null : 'selection')"
              :class="{ active: mode === 'selection' }"
            >
              {{ displayLabelName("profile", "assigned-resources", "select") }}
            </button>
          </li>
          <li class="action" :style="deleteButtonStyle">
            <button class="action-btn" @click="showDeleteModal = true">
              <svg-icon icon="garbage"></svg-icon>
            </button>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="showDeleteModal"
      class="confirm-modal"
      type="success"
      :confirm-action="deleteBreaks"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="showDeleteModal"
      @close="showDeleteModal = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("profile", "assigned-resources", "delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("profile.assign-resources.delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapGetters } from "vuex";
import { formatDate } from "@/services/helpers";

export default {
  name: "EditAssignedResource",
  data() {
    return {
      selectedItems: [],
      mode: null,
      showDeleteModal: false
    };
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  created() {
    this.getResourceAssignmentBreaks(this.resourceAssignmentId);
  },
  watch: {
    $route: {
      handler(val) {
        if (val.matched.length === 3) {
          this.getResourceAssignmentBreaks(this.resourceAssignmentId);
        }
      }
    }
  },
  computed: {
    ...mapGetters("resourceAssignment", ["resourceAssignmentBreaks"]),
    ...mapGetters("settings", ["globalDateFormat"]),
    resourceAssignmentId() {
      const { assignedResourceId } = this.$route.params;
      return assignedResourceId;
    },
    deleteButtonStyle() {
      if (this.mode === "selection" && this.selectedItems.length) {
        return {
          visibility: "visible"
        };
      }

      return {
        visibility: "hidden"
      };
    }
  },
  methods: {
    ...mapActions("resourceAssignment", [
      "getResourceAssignmentBreaks",
      "deleteResourceAssignmentBreaks"
    ]),
    displayDate(date) {
      if (!date) {
        return this.displayLabelName(
          "profile",
          "assigned-resources",
          "select-date"
        );
      }
      return formatDate(date, this.globalDateFormat, true);
    },

    setMode(mode) {
      this.selectedItems = [];
      this.mode = mode;
    },
    handleSelection(id) {
      const index = this.selectedItems.findIndex(i => i === id);
      if (index < 0) {
        this.selectedItems.push(id);
      } else {
        this.selectedItems.splice(index, 1);
      }
    },
    deleteBreaks() {
      this.deleteResourceAssignmentBreaks({
        resAssignmentId: this.resourceAssignmentId,
        items: this.selectedItems
      }).then(response => {
        this.selectedItems = [];
        if (response) {
          this.getResourceAssignmentBreaks(this.resourceAssignmentId);
        }
      });
    }
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
